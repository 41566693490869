<template>
  <div class="flex flex-row w-full mx-auto lg:w-page-width mt-24 lg:mt-20 mb-3 lg:mb-6">
    <router-view/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AdminView",
  computed: {
    ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('admin', ['adminDashboardData'])
  },
  methods: {
    ...mapActions('dictionaries', ['fetchPostCategoriesData', 'fetchUserFiltersData']),
    ...mapActions('admin', ['fetchAdminDashboardData']),
  },
  mounted() {
    this.fetchUserFiltersData();

    if(!this.postCategoriesData) {
      this.fetchPostCategoriesData();
    }
  }
}
</script>
